import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import logoAccuratio from '../assets/logo-Accuratio-invert.png';
import LoginButton from './loginButton.js';
import LogoutButton from './LogoutButton.js';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

const MyNavbar = () => {
  const buttonStyle = {
    width: '10vw',
    height: '55px',
    marginLeft: '1.3vw',
    marginRight: '0'
  };

  const { isAuthenticated } = useAuth0();

  return (
    <Navbar >
      <Container>
        
        <Navbar.Brand href="/">
          <img
            src={logoAccuratio}
            alt="Logo Accuratio"
            style={{ width: '150px', height: 'auto' }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
          <Nav className="align-items-center">
            <Nav.Link as={Link} to="/" className="text-light" style={{buttonStyle}}>Inicio</Nav.Link>
            <Nav.Link as={Link} to="/about" className="text-light" style={{buttonStyle}}>Acerca de</Nav.Link>
          </Nav>
          <Nav className="align-items-center">
            {isAuthenticated ? (
              <>
                <Link to="/perfil">
                  <Button variant="secondary" style={buttonStyle}>Perfil</Button>
                </Link>
                <Link to="/dashboard">
                  <Button variant="secondary" style={buttonStyle}>Dashboards</Button>
                </Link>
                <LogoutButton style={buttonStyle} />
              </>
            ) : (
              <LoginButton style={buttonStyle} />
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;
