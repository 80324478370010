import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faGlobe as faSolidGlobe } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  const footerStyle = {
    paddingTop: '10px',
    height: '10vh',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: '#343a40',
    color: 'white',
  };

  const accuratioStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
  };

  const redes = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
    gap: '10px',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none', 
  };

  const linkHoverStyle = {
    textDecoration: 'underline', 
  };

  return (
    <footer className="bg-dark text-light" style={footerStyle}>
      <Container>
        <Row className="align-items-center" style={{ height: '100%' }}>
          <Col md={4} className="text-center" style={accuratioStyle}>
            <p>© 2024 Accuratio. Todos los derechos reservados.</p>
          </Col>
          <Col md={4} className="text-center" style={accuratioStyle}>
            <a href="https://wa.me/5491156666345" style={linkStyle} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} /> Contacto
            </a>
          </Col>
          <Col md={4} className="text-center" style={redes}>
            <a href="https://accuratio.global/" style={linkStyle} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSolidGlobe} /> Web
            </a>
            <a href="https://www.facebook.com/p/Accuratio-100063521142307/" style={linkStyle} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} /> Facebook
            </a>
            <a href="https://www.linkedin.com/company/accuratio/" style={linkStyle} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedinIn} /> LinkedIn
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
