import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import background from '../assets/background.jpg'; 

const Perfil = () => {
  const { user } = useAuth0();

  const stylePerfil = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '80vh', 
    background: `url(${background})`, 
    
    backgroundPosition: 'center', 
    color: 'white', 
    padding: '20px', 
  };

  return (
    <div style={stylePerfil}>
      {user?.picture && <img src={user.picture} alt={user?.name} />}
      <h2>{user?.name}</h2>
      {user && (
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {user.email && <li>Email: {user.email}</li>}
          {user.nickname && <li>Usuario: {user.nickname}</li>}
        </ul>
      )}
    </div>
  );
};

export default Perfil;
