import React from "react";
import background from "../assets/background.jpg";

const Inicio = () => {
    const estiloInicio = {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        height: '80vh',
        
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
        margin: 0,
        padding: 0,
        overflowX: 'hidden', 
    };
    const text =  {
        marginTop: '25vh'
    };

    return (
        <div style={estiloInicio} >
            
            <h1 style={text}>Accuratio Power BI Embedded</h1>
        </div>
    );
};

export default Inicio;
