import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import logoAccuratio from '../assets/logoAccuratio.js'; 

const Profile = () => {
    const { user, isAuthenticated } = useAuth0();
    const [embedConfig, setEmbedConfig] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDashboardConfig = async () => {
            try {
                // Obtener configuración de dashboard del servidor
                const dashboardResponse = await fetch(`http://localhost:3002/dashboard-config?sub=${user.sub}`);
                if (!dashboardResponse.ok) {
                    throw new Error('No se pudo obtener la configuración del dashboard');
                }
                const dashboardData = await dashboardResponse.json();
                
                // Obtener token de incrustación
                const tokenResponse = await fetch(`http://localhost:3002/powerbi-token?sub=${user.sub}`);
                if (!tokenResponse.ok) {
                    throw new Error('No se pudo obtener el token de incrustación');
                }
                const tokenData = await tokenResponse.json();
                
                if (tokenData.token) {
                    setEmbedConfig({
                        type: 'report',
                        id: dashboardData.reportid,
                        embedUrl: dashboardData.embedurl,
                        accessToken: tokenData.token,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                }
                            },
                            background: models.BackgroundType.Transparent,
                        }
                    });
                } else {
                    setError("No se pudo obtener el token de incrustación");
                }
            } catch (err) {
                console.log(err);
                setError("Error al obtener la configuración del dashboard o el token de incrustación");
            } finally {
                setLoading(false);
            }
        };

        if (isAuthenticated) {
            fetchDashboardConfig();
        }
    }, [isAuthenticated, user]);

    return (
        isAuthenticated ? (
            <article className="text-center">
                
                
                
                {!loading && embedConfig && (
                    <div className="powerbi-container">
                        <PowerBIEmbed
                            embedConfig={embedConfig}
                            eventHandlers={
                                new Map([
                                    ['loaded', function () { console.log('Report loaded'); }],
                                    ['rendered', function () { console.log('Report rendered'); }],
                                    ['error', function (event) { console.log(event.detail); }],
                                    ['visualClicked', () => console.log('Visual clicked')],
                                    ['pageChanged', (event) => console.log(event)],
                                ])
                            }
                            cssClassName={"reportClass"}
                            getEmbeddedComponent={(embeddedReport) => {
                                window.Report = embeddedReport;
                            }}
                        />
                    </div>
                )}
                {loading && <p>Cargando...</p>}
                {error && <p>{error}</p>}
            </article>
        ) : (
            <div>
            </div>
        )
    );
};

export default Profile;
