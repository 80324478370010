import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Perfil from './Components/perfil';
import Profile from './Components/Profile';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MyNavbar from './Components/navbar';
import Footer from './Components/footer';
import Inicio from './Components/Inicio';
import About from './Components/about';



function App() {
    return (
        <BrowserRouter>
            <Container fluid className="bg-dark text-light min-vh-100">
                <MyNavbar />
                <Routes>
                    <Route path="/perfil" element={<Perfil />} />
                    <Route path="/dashboard" element={<Profile />} />
                    <Route path="/" element={<Inicio />} />
                    <Route path='/about' element={<About/>}/>
                </Routes>
                <Footer/>
            </Container>
        </BrowserRouter>
    );
}

export default App;
