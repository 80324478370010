import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css'




const LoginButton = ({style}) => {
    const { loginWithRedirect, isAuthenticated, user, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const addUserToDatabase = async () => {
            if (isAuthenticated && user) {
                try {
                    // Obtener el token de acceso si es necesario
                    const token = await getAccessTokenSilently();

                    const response = await axios.post(
                        "http://localhost:3002/adduser",
                        {
                            username: user.name, 
                            sub: user.sub
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    );
                    console.log("Usuario agregado:", response.data);
                } catch (error) {
                    console.error("Error al agregar usuario a la base de datos:", error);
                }
            }
        };

        addUserToDatabase();
    }, [isAuthenticated, user, getAccessTokenSilently]);

    return (
        !isAuthenticated && (
            <Button variant="primary" onClick={() => loginWithRedirect()} style={style}>
                Iniciar  Sesión
            </Button>
        )
    );
};


export default LoginButton;
