import { useAuth0 } from "@auth0/auth0-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';

const LogoutButton = ({style}) => {
    const { logout, isAuthenticated } = useAuth0();
    return (
        isAuthenticated && (
            <div className="d-flex justify-content-end p-3">
                <Button
                    variant="danger"
                    onClick={() => logout()}
                    style={{
                        ...style,
                        backgroundColor: '#DA009B',
                        borderColor: '#DA009B'
                    }}
                >
                    Cerrar sesión
                </Button>
            </div>
        )
    );
}

export default LogoutButton;

