import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AccuOficinas from '../assets/map_Accu-oficinas.jpg'


const About = () => {
    const aboutStyle = {
        padding: '40px 0',
        textAlign: 'center',
        color: 'white',
        height: '50vh', // Ocupa toda la altura de la ventana
        display: 'flex',
        alignItems: 'center' // Centra verticalmente el contenido
    };

    return (
        <Container fluid>
            <Row>
                <Col md={6} style={aboutStyle}>
                    <div>
                        <h2>Servicios Profesionales basados en IT</h2>
                        <p>
                            Asistimos a las organizaciones y a sus personas a mejorar sus experiencias de trabajo basados en Datos y en Tecnología Informática.
                        </p>
                        <p>
                            Somos hacedores, enfocándonos en resultados y los procesos para llevarlos a cabo. La confianza, conexión y colaboración son nuestras bases y nos alientan para lograr objetivos comerciales y sustentables.
                        </p>
                    </div>
                </Col>
                <Col md={6} style={{ padding: 0 }}>

                <img
    src={AccuOficinas}
    alt="Accuratio Office"
    style={{
        width: '80%',
        height: '80vh',
        borderRadius: '50px',
        objectFit: 'cover',
        objectPosition: 'top right', // Ajusta la posición de la imagen para recortar desde la parte superior
        marginLeft: '9%',
        marginBottom: '10px',
        padding: '40px' // Asegúrate de usar una unidad válida
    }}
/>



                </Col>
            </Row>
        </Container>
    );
};

export default About;
